import React, {useRef} from 'react';
import {Field, Form} from 'react-final-form';
import {
    Button,
    Card,
    CardActions,
    CircularProgress,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useLogin, useNotify, useSafeSetState, useTranslate} from 'ra-core';
import {Notification} from "react-admin";
import classnames from "classnames";
import backImage from "../assets/images/Union.png";
import {ReactComponent as Logo} from '../assets/images/logo.svg'
import {Link} from "react-router-dom";
import CheckboxWrapper from "../components/Checkbox.tsx";
import LocaleSwitcher from "../layout/LocaleSwitcher";


interface Props {
    redirectTo?: string;
}

interface FormData {
    username: string;
    password: string;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `url(${backImage})`,
        },
        wrapper: {
            margin: "auto",
        },
        card: {
            boxSizing: 'border-box',
            minWidth: 405,
            marginTop: theme.spacing(8),
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            borderRadius: 15,
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            borderRadius: 0,
            marginTop: '1em',
            padding: 0,
            background: "#D5F3F6",

        },
        textField: {
            color: "#d3d3d3",
            "&:focused": {
                color: "rgba(3, 0, 5, 0.5)"
            },
            width: 405,
            borderRadius: 0,

        },
        actionWrapper: {
            width: "100%",
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        linkWrapper: {
            width: "100%",
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        button: {
            width: '45%',
            height: 40,
            borderRadius: 5,
            background: "linear-gradient(180deg, #0AA5B7 13.64%, #00929C 128.79%)",
            fontFamily: " IBM Plex Sans",
            fontWeight: "bold",
            fontSize: "0.875rem",
        },
        formControl: {
            width: '45%',
            height: 40,
            borderRadius: 5,
            marginRight: theme.spacing(0),
            background: "#CCCCCC",
        },
        labelText: {
            fontFamily: " IBM Plex Sans",
            fontWeight: "bold",
            fontSize: "0.875rem",
            color: theme.palette.primary.main,
        },
        link: {
            paddingTop: theme.spacing(3),
            color: theme.palette.primary.main,
            fontFamily: " IBM Plex Sans",
            fontSize: "0.875rem",
            textDecoration: "none",
            "&:hover": {
                color: theme.palette.primary.main,
                textDecoration: "underline",
            },
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    {name: 'RaLoginForm'}
);

const Input = ({
                   meta: {touched, error}, // eslint-disable-line react/prop-types
                   input: inputProps, // eslint-disable-line react/prop-types
                   ...props
               }) => {
    const classes = useStyles()
    return (
        <TextField
            className={classes.textField}
            variant='filled'
            style={{
                color: "#d3d3d3",
            }}
            error={!!(touched && error)}
            helperText={touched && error}
            InputProps={{disableUnderline: true}}
            {...inputProps}
            {...props}
            fullWidth
        />)
};

export const LoginPage = (props: Props) => {
    const {redirectTo} = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = useStyles(props);
    const {
        title,
        classes: classesOverride,
        className,
        children,
        notification,
        staticContext,
        ...rest
    } = props;
    const containerRef = useRef();

    const validate = (values: FormData) => {

        const errors = {username: undefined, password: undefined};

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    function refreshPage() {
        window.location.reload(false);
    }

    const submit = values => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
                refreshPage()
            })
            .catch(error => {
                setLoading(false);
                notify(
                    error,
                    {
                        type: 'warning',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                        ? error.message
                                        : undefined,
                        },
                    }
                );
            })


    };
    return (
        <div
            className={classnames(classes.main,)}
            {...rest}
            ref={containerRef}
        >
            <LocaleSwitcher/>
            <div className={classes.wrapper}>
                <Logo
                    style={{
                        width: '100%',
                    }}
                />
                <Card className={classes.card}>
                    <Form
                        onSubmit={submit}
                        validate={validate}
                        initialValues={{rememberMe: true}}
                        render={({handleSubmit, values, reset, submitting, pristine,}) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            id="username"
                                            name="username"
                                            component={Input}
                                            label={translate('ra.auth.username')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            id="password"
                                            name="password"
                                            component={Input}
                                            label={translate('ra.auth.password')}
                                            type="password"
                                            disabled={loading}
                                            autoComplete="current-password"
                                        />
                                    </div>
                                </div>
                                <CardActions
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Grid className={classes.actionWrapper}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                            className={classes.button}
                                        >
                                            {loading && (
                                                <CircularProgress
                                                    className={classes.icon}
                                                    size={18}
                                                    thickness={2}
                                                />
                                            )}
                                            {translate('ra.auth.sign_in')}
                                        </Button>

                                        <FormControlLabel
                                            className={classes.formControl}
                                            control={
                                                <Field
                                                    name="rememberMe"
                                                    component={CheckboxWrapper}
                                                    type="checkbox"
                                                />}
                                            label={<Typography
                                                className={classes.labelText}
                                            > {translate("loginPage.stayLoggedInLabel").toUpperCase()} </Typography>}
                                        />

                                    </Grid>
                                    <Grid className={classes.linkWrapper}>
                                        <Link
                                            className={classes.link}
                                            to={'/create-profile'}> {translate("loginPage.createAccountButton")} </Link>
                                        <Link
                                            className={classes.link}
                                            to={'/forgot-password'}>  {translate("loginPage.forgotPasswordButton")}</Link>
                                    </Grid>
                                </CardActions>
                            </form>
                        )}
                    />
                </Card>
                <Notification/>
            </div>
        </div>
    )
};








